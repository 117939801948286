import { DeepPartial, UseFormSetValue } from "react-hook-form";

import { usePrevious } from "@sellernote/shared/src/utils/common/hook";

import { ReportIssueForm } from ".";
import useSetDebuggingDataFromGlobalState from "./useSetDebuggingDataFromGlobalState";

/**
 * 자동으로 디버깅용 데이터를 셋팅
 */
export default function useAutoApplyDebuggingData({
  enabled,
  isDirtyForm,
  setFormValue,
  formValue,
}: {
  enabled: boolean;
  isDirtyForm: boolean;
  setFormValue: UseFormSetValue<ReportIssueForm>;
  formValue: DeepPartial<ReportIssueForm["debuggingData"]>;
}) {
  const previousEnabled = usePrevious(enabled);

  const { setDebuggingDataFromGlobalState } =
    useSetDebuggingDataFromGlobalState({
      formValue,
      setFormValue,
    });

  /**
   * 전에는 활성화가 아니었다가 활성화로 뀐 경우인지(= form이 닫혀있었다가 열린 경우 인지) 여부
   */
  const isChangedToEnabled = enabled !== previousEnabled && enabled;

  // form이 새로열렸는데, 데이터가 없는 경우 디버깅용 데이터를 자동으로 업데이트 해준다
  const needUpdateDebuggingData = isChangedToEnabled && !isDirtyForm;

  if (needUpdateDebuggingData) {
    setDebuggingDataFromGlobalState();
  }
}
