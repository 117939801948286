import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useLocation } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  IconButton,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { getObjectEntries } from "@sellernote/shared/src/utils/common/object";

import { ReportIssueForm } from ".";
import useSetDebuggingDataFromGlobalState from "./useSetDebuggingDataFromGlobalState";

export default function DebuggingDataForm({
  control,
  setValue,
}: {
  control: Control<ReportIssueForm>;
  setValue: UseFormSetValue<ReportIssueForm>;
}) {
  const { debuggingData } = useWatch({ control });

  const { setDebuggingDataFromGlobalState, debuggingDataFromGlobalState } =
    useSetDebuggingDataFromGlobalState({
      formValue: debuggingData,
      setFormValue: setValue,
    });

  // URL은 window.location.href로 가져올 수 있으나, 그것을 사용하면 URL 변경시 리렌더링이 되지 않아서 useLocation으로 가져온 상태를 사용한다
  const { pathname } = useLocation();

  /**
   * form과 Global debuggingData 상태가 동일한지 여부
   * - useMemo를 사용하면 RHF의 nested 상태 변화가 감지되지 않아 memoization 없이 사용함
   */
  const isSameFromGlobalState: boolean = (() => {
    return getObjectEntries(debuggingData || {}).every(([key, value]) => {
      if (key === "pageUrl") {
        return value === `${window.location.origin}${pathname}`;
      }

      return value === (debuggingDataFromGlobalState?.[key] || "");
    });
  })();

  const clearDebuggingData = () => {
    setValue("debuggingData", {
      pageUrl: "",
      bidId: "",
      userId: "",
      userCompany: "",
    });
  };

  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <InputLabel>Debugging 정보</InputLabel>

        <IconButton onClick={clearDebuggingData}>
          <CancelIcon color="action" />
        </IconButton>

        <IconButton onClick={setDebuggingDataFromGlobalState}>
          <AutorenewIcon color="primary" />
        </IconButton>

        {!isSameFromGlobalState && (
          <Typography variant="caption" color="error">
            ⚠️ 현재 열려있는 페이지와 정보가 다른 것 같습니다 (좌측 버튼으로
            갱신가능)
          </Typography>
        )}
      </Stack>

      <Paper variant="elevation" elevation={1} sx={{ padding: "10px" }}>
        <Stack spacing={2}>
          <Controller
            name="debuggingData.pageUrl"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextField
                  sx={{ flex: 1 }}
                  value={value}
                  fullWidth
                  variant="outlined"
                  label="URL"
                  size="small"
                  onChange={onChange}
                />
              );
            }}
          />
          <Stack direction="row" spacing={1}>
            <Controller
              name="debuggingData.bidId"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextField
                    sx={{ flex: 1 }}
                    value={value}
                    fullWidth
                    variant="outlined"
                    label="의뢰 ID"
                    size="small"
                    onChange={onChange}
                  />
                );
              }}
            />

            <Controller
              name="debuggingData.userId"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextField
                    sx={{ flex: 1 }}
                    value={value}
                    fullWidth
                    variant="outlined"
                    label="사용자 ID"
                    size="small"
                    onChange={onChange}
                  />
                );
              }}
            />

            <Controller
              name="debuggingData.userCompany"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextField
                    sx={{ flex: 1 }}
                    value={value}
                    fullWidth
                    variant="outlined"
                    label="회사명"
                    size="small"
                    onChange={onChange}
                  />
                );
              }}
            />
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
}
