import { useEffect, useState } from "react";
import { Box, Popper } from "@mui/material";
import { useAtomValue } from "jotai";

import { FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD } from "@sellernote/shared/src/constants/forwarding/adminZIndexLevelRecord";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/shared/src/jotaiStates/auth";
import { SLACK_ID_RECORD } from "@sellernote/shared/src/services/slack";

import Form from "./Form";

const TRIGGER_BOTTOM_POSITION = 16;
const TRIGGER_RIGHT_POSITION = 32;

export { TRIGGER_RIGHT_POSITION };

export default function ReportIssue() {
  const currentUser = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const { handleSnackbarOpen } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  /**
   * Popper가 닫혔을때 form이 언마운트 되면 입력데이터가 유실되므로,
   * form의 unmount를 막기 위해, 닫는 것이 아닌 화면에서 보이지 않는 영역에 anchor를 두는 방식으로 처리한다.
   */

  const handleTriggerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!currentUser) {
      handleSnackbarOpen("로그인 후 이슈리포트를 이용해주세요.", "error");
      return;
    }

    const reporterSlackInfo = SLACK_ID_RECORD[currentUser.email];
    if (!reporterSlackInfo?.id) {
      handleSnackbarOpen(
        "요청자의 Slack ID를 찾을 수 없습니다. 개발자에게 문의해주세요.",
        "error"
      );
      return;
    }

    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  /**
   * ESC로 닫기
   */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && !!anchorEl) {
        setAnchorEl(null);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [anchorEl]);

  return (
    <>
      <Box
        onClick={handleTriggerClick}
        sx={{
          position: "fixed",
          bottom: `${TRIGGER_BOTTOM_POSITION}px`,
          right: `${TRIGGER_RIGHT_POSITION}px`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          padding: "12px",
          borderRadius: "50%",
          cursor: "pointer",
          backgroundImage: "url(/images/issue-report.webp)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "48px",
          height: "48px",
        }}
        style={{
          zIndex: FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.tableHeaderFilter,
        }}
      />

      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        style={{
          zIndex: FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.tableHeaderFilter,
        }}
        keepMounted
      >
        <Form hideForm={() => setAnchorEl(null)} />
      </Popper>
    </>
  );
}
