import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useAtomValue } from "jotai";

import Navigation from "@sellernote/shared/src/admin-ui/components/Navigation";
//TODO : 엔트리 컴포넌트의 이름을 page경로 그대로 수정
import NewWindowOpenFilePreview from "@sellernote/shared/src/admin-ui/containers/FileName/FilePreview/NewWindowOpenFilePreview";
import EnvWarningBadge from "@sellernote/shared/src/components/EnvWarningBadge";
import { APP_ENV } from "@sellernote/shared/src/constants";
import withRequireAuth from "@sellernote/shared/src/hocs/withRequireAuth";
import { FORWARDING_ADMIN_AUTH_ATOMS } from "@sellernote/shared/src/jotaiStates/auth";

import ReportIssue from "containers/ReportIssue";
import AccountManagement from "pages/account-management";
import AdminUser from "pages/admin-user";
import AdminUserDetail from "pages/admin-user/:id";
import ChangeAdminUserPassword from "pages/admin-user/change-password";
import Bid from "pages/bid";
import BidCreate from "pages/bid/create";
import BidCreateQuotation from "pages/bid/create/quotation/:freightType";
import BidQuotationEdit from "pages/bid/detail/:id/quotation/:quotationType";
import BidQuotationNew from "pages/bid/detail/:id/quotation/new";
import BusinessOverview from "pages/business-management/business-overview";
import BusinessManagementForOperation from "pages/business-management/operation";
import Profit from "pages/business-management/profit";
import DepositManagement from "pages/lab/deposit-management";
import OrderDetail from "pages/order/:id";
import OrderSub from "pages/order/sub";
import PartnerManagement from "pages/partner-management";
import PartnerDetail from "pages/partner-management/:id";
import Promotion from "pages/promotion";
import PromotionDetail from "pages/promotion/:id";
import SettlementDepositManagement from "pages/settlement/deposit-management";
import PurchaseManagement from "pages/settlement/purchaseManagement";
import SettlementSalesManagement from "pages/settlement/salesManagement";
import SettlementWithdrawalManagement from "pages/settlement/withdrawal-management";
import VesselScheduleAir from "pages/vessel-schedule/air";
import VesselScheduleFcl from "pages/vessel-schedule/fcl";
import VesselScheduleLclPort from "pages/vessel-schedule/lcl/port";
import VesselScheduleLclWarehouse from "pages/vessel-schedule/lcl/warehouse";
import AdminUserRegister from "./pages/admin-user/register";
import BidDetail from "./pages/bid/detail/:id";
import CouponDetail from "./pages/coupon/:id";
import Coupon from "./pages/coupon/index";
import Dashboard from "./pages/dashboard";
import FareManagementAirList from "./pages/fare-management/air";
import FareManageFclList from "./pages/fare-management/fcl";
import FareManagementLclList from "./pages/fare-management/lcl";
import InlandFareAirList from "./pages/inland-fare/air/list";
import InlandFareFclList from "./pages/inland-fare/fcl/list";
import InlandFareLclList from "./pages/inland-fare/lcl/list";
import Login from "./pages/login";
import Order from "./pages/order";
import CountryList from "./pages/place/country";
import PortList from "./pages/place/ports";
import ZoneList from "./pages/place/zones";
import GuideUser from "./pages/promotion/guide";
import Settlement from "./pages/settlement";
import TeamDetail from "./pages/team/:id";
import TeamList from "./pages/team/list";
import TradingStatement from "./pages/tradingStatement/:invoiceType/:bidId/shipmentType";
import ConsolidationTrelloList from "./pages/trello/consolidation";
import ExportTrelloList from "./pages/trello/export";
import GeneralTrelloList from "./pages/trello/general";
import UserManagement from "./pages/users/list";
import UserDetail from "./pages/users/list/:id";

function AppRouter({ children }: { children: React.ReactNode }) {
  const loggedIn = useAtomValue(FORWARDING_ADMIN_AUTH_ATOMS.ADMIN_LOGGED_IN);

  return (
    <Router>
      <div
        style={{
          display: "flex",
          overflow: "hidden",
          width: "100%",
          height: "100%",
        }}
      >
        {APP_ENV === "staging" && <EnvWarningBadge />}

        {loggedIn && (
          <>
            <Navigation />

            {/* route 변경시에도 데이터가 유지되어아햐므로 여기에 배치 */}
            <ReportIssue />
          </>
        )}

        <div style={{ overflow: "hidden", flex: 1 }}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>

            <Route exact path="/login" component={Login} />

            <Route
              exact
              path="/dashboard"
              component={withRequireAuth(Dashboard)}
            />

            <Route
              exact
              path="/admin-user"
              component={withRequireAuth(AdminUser)}
            />
            <Route
              exact
              path="/admin-user/register"
              component={withRequireAuth(AdminUserRegister)}
            />
            <Route
              exact
              path="/admin-user/:id"
              component={withRequireAuth(AdminUserDetail)}
            />
            <Route
              exact
              path="/admin-user/change-password/:id"
              component={withRequireAuth(ChangeAdminUserPassword)}
            />

            <Route
              exact
              path="/vessel-schedule/fcl"
              component={withRequireAuth(VesselScheduleFcl)}
            />
            <Route
              exact
              path="/vessel-schedule/lcl/port"
              component={withRequireAuth(VesselScheduleLclPort)}
            />
            <Route
              exact
              path="/vessel-schedule/lcl/warehouse"
              component={withRequireAuth(VesselScheduleLclWarehouse)}
            />
            <Route
              exact
              path="/vessel-schedule/air"
              component={withRequireAuth(VesselScheduleAir)}
            />

            <Route
              exact
              path="/bid/create"
              component={withRequireAuth(BidCreate)}
            />
            <Route
              exact
              path="/bid/create/quotation/:freightType"
              component={withRequireAuth(BidCreateQuotation)}
            />

            <Route exact path="/bid" component={withRequireAuth(Bid)} />
            <Route
              exact
              path="/bid/detail/:id"
              component={withRequireAuth(BidDetail)}
            />
            <Route
              exact
              path="/bid/detail/:id/quotation/new"
              component={withRequireAuth(BidQuotationNew)}
            />
            <Route
              exact
              path="/bid/detail/:id/quotation/:quotationType"
              component={withRequireAuth(BidQuotationEdit)}
            />

            <Route
              exact
              path="/tradingStatement/:invoiceType/:bidId/:shipmentType"
              component={withRequireAuth(TradingStatement)}
            />

            <Route
              exact
              path="/inland-fare/fcl/list"
              component={withRequireAuth(InlandFareFclList)}
            />

            <Route
              exact
              path="/inland-fare/lcl/list"
              component={withRequireAuth(InlandFareLclList)}
            />

            <Route
              exact
              path="/inland-fare/air/list"
              component={withRequireAuth(InlandFareAirList)}
            />

            <Route
              exact
              path="/place/zones"
              component={withRequireAuth(ZoneList)}
            />
            <Route
              exact
              path="/place/ports"
              component={withRequireAuth(PortList)}
            />

            <Route
              exact
              path="/place/countryList"
              component={withRequireAuth(CountryList)}
            />

            <Route
              exact
              path="/team/list"
              component={withRequireAuth(TeamList)}
            />
            <Route
              exact
              path="/team/:id"
              component={withRequireAuth(TeamDetail)}
            />

            <Route
              exact
              path="/partner-management"
              component={withRequireAuth(PartnerManagement)}
            />
            <Route
              exact
              path="/partner-management/:id"
              component={withRequireAuth(PartnerDetail)}
            />

            <Route
              exact
              path="/users/list"
              component={withRequireAuth(UserManagement)}
            />
            <Route
              exact
              path="/users/list/:id"
              component={withRequireAuth(UserDetail)}
            />
            <Route
              exact
              path="/fare-management/fcl/list"
              component={withRequireAuth(FareManageFclList)}
            />

            <Route
              exact
              path="/fare-management/lcl/list"
              component={withRequireAuth(FareManagementLclList)}
            />

            <Route
              exact
              path="/fare-management/air/list"
              component={withRequireAuth(FareManagementAirList)}
            />

            <Route
              exact
              path="/settlement"
              component={withRequireAuth(Settlement)}
            />
            <Route
              exact
              path="/settlement/salesManagement"
              component={withRequireAuth(SettlementSalesManagement)}
            />
            <Route
              exact
              path="/settlement/purchaseManagement"
              component={withRequireAuth(PurchaseManagement)}
            />
            <Route
              exact
              path="/settlement/deposit-management"
              component={withRequireAuth(SettlementDepositManagement)}
            />
            <Route
              exact
              path="/settlement/withdrawal-management"
              component={withRequireAuth(SettlementWithdrawalManagement)}
            />

            <Route
              exact
              path="/trello/general"
              component={withRequireAuth(GeneralTrelloList)}
            />
            <Route
              exact
              path="/trello/consolidation"
              component={withRequireAuth(ConsolidationTrelloList)}
            />
            <Route
              exact
              path="/trello/export"
              component={withRequireAuth(ExportTrelloList)}
            />

            <Route exact path="/coupon" component={withRequireAuth(Coupon)} />
            <Route
              exact
              path="/coupon/:id"
              component={withRequireAuth(CouponDetail)}
            />

            <Route
              exact
              path="/business-management/operation"
              component={withRequireAuth(BusinessManagementForOperation)}
            />
            <Route
              exact
              path="/business-management/business-overview"
              component={withRequireAuth(BusinessOverview)}
            />
            <Route
              exact
              path="/business-management/profit"
              component={withRequireAuth(Profit)}
            />
            <Route
              exact
              path="/business-management/guide"
              component={withRequireAuth(GuideUser)}
            />

            <Route
              exact
              path="/file/preview"
              component={withRequireAuth(NewWindowOpenFilePreview)}
            />

            <Route
              exact
              path="/promotion"
              component={withRequireAuth(Promotion)}
            />
            <Route
              exact
              path="/promotion/:id"
              component={withRequireAuth(PromotionDetail)}
            />

            <Route exact path="/order" component={withRequireAuth(Order)} />
            <Route
              exact
              path="/order/sub"
              component={withRequireAuth(OrderSub)}
            />
            <Route
              exact
              path="/order/:id"
              component={withRequireAuth(OrderDetail)}
            />
            <Route
              exact
              path="/order/sub/:id"
              component={withRequireAuth(OrderDetail)}
            />
            <Route
              exact
              path="/lab/deposit-management"
              component={withRequireAuth(DepositManagement)}
            />

            {/** 계정 > 거래처 관리 */}
            <Route
              exact
              path="/account-management"
              component={withRequireAuth(AccountManagement)}
            />
          </Switch>

          {children}
        </div>
      </div>
    </Router>
  );
}
export default AppRouter;
